import React from 'react';
import { PrimaryButton, NamedLink } from '../../components';
import css from './StopAction.module.css';

const StopAction = (props) => {
    const {
        image,
        title,
        text,
        buttonText,
        buttonDisabled,
        buttonAction,
        namedLinkParams,
    } = props;

    const {
        goTo,
        linkParams,
        linkText,
    } = namedLinkParams || {};

    return <div className={css.root}>
        {image && <div className={css.image}>
            {image}
        </div>}

        {title && <div className={css.title}>
            {title}
        </div>}

        {text && <div className={css.text}>
            {text}
        </div>}

        {buttonText && <PrimaryButton
            type="button"
            disabled={buttonDisabled}
            onClick={() => {
                if (buttonAction) {
                    buttonAction();
                };
            }}
        >
            {buttonText}
        </PrimaryButton>}

        {goTo && <NamedLink
            name={goTo}
            params={linkParams}
        >
            <PrimaryButton type="button" >
                {linkText}
            </PrimaryButton>
        </NamedLink>}
    </div>
};

export default StopAction;
