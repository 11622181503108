import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { isTooManyEmailVerificationRequestsError } from '../../util/errors';
import { IconEmailAttention, InlineTextButton, NamedLink } from '../../components';

import css from './ModalMissingInformation.module.css';

const AddPayoutReminder = props => {
    const {
        className,
    } = props;


    const paymentMethodPageLink = (
        <NamedLink rootClassName={css.helperLink} name="PaymentMethodsPage" >
            <FormattedMessage id="ModalMissingInformation.addPaymentLink" />
        </NamedLink>
    );



    return (
        <div className={className}>
            <IconEmailAttention className={css.modalIcon} />
            <p className={css.modalTitle}>
                <FormattedMessage id="ModalMissingInformation.addPaymentMethodTitle" />
            </p>
            <p className={css.modalMessage}>
                <FormattedMessage id="ModalMissingInformation.addPaymentMethodMessage" />
            </p>


            <div className={css.bottomWrapper}>
                <p className={css.helperText}>
                    <FormattedMessage
                        id="ModalMissingInformation.redirectToPaymentPage"
                        values={{ paymentMethodPageLink }}
                    />
                </p>
            </div>
        </div>
    );
};

export default AddPayoutReminder;
