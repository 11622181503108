import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { parse } from 'url';
import { isOriginInUse } from '../../util/search';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/ShopWorldWidePage/CLEAR_UPDATED_FORM';
export const GET_LISTINGS = 'app/ShopWorldWidePage/GET_LISTINGS';


// ================ Reducer ================ //

const initialState = {
  currentPageResultIds: [],
  listingCategoryName: null
};

const resultIds = data => {
  const listings = data.data;
  return listings
    .filter(l => !l.attributes.deleted && l.attributes.state === 'published')
    .map(l => l.id);
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_LISTINGS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        listingCategoryName: payload.listingCategoryName
      }

    default:
      return state;
  }
}


// ================ Action creators ================ //

export const getWorldWideListings = (searchParams, config, listingCategoryName) => (dispatch, getState, sdk) => {

  return sdk.listings.query(searchParams)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };

      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch({
        type: GET_LISTINGS,
        payload: { data: response.data, listingCategoryName }
      })
    })
}

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {

  const listingCategory = params.category;
  const listingCategories = config.categoryConfiguration.categories;

  const listingCategoryName = listingCategories.find(({ id }) => id == listingCategory)?.name
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  const searchParams = {
    page: 1,
    perPage: 100,
    pub_categoryLevel1: listingCategory,
    pub_listingType: "product",
    include: ['author', 'images'],
    'fields.listing': [
      'title',
      'geolocation',
      'price',
      'deleted',
      'state',
      'publicData.listingType',
      'publicData.categoryLevel1',
      'publicData.shop_url',
      'publicData.transactionProcessAlias',
      'publicData.unitType',
      'publicData.pickupEnabled',
      'publicData.shippingEnabled',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.bio'],
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  }
  return dispatch(getWorldWideListings(searchParams, config, listingCategoryName))

};

