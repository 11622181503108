import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { parse } from 'url';
import { isOriginInUse } from '../../util/search';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { cleanSearchFromConflictingParams } from '../SearchPage/SearchPage.shared';
import { postGetValidServices } from '../../util/api';
import { createSlug } from '../../util/urlHelpers';
import { locationBounds } from '../../util/googleMaps';
import { createResourceLocatorString } from '../../util/routes';

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/ServicesSearchNotificationPage/CLEAR_UPDATED_FORM';
export const NOTIFICATIONS_SENT = 'app/ServicesSearchNotificationPage/NOTIFICATIONS_SENT';


// ================ Reducer ================ //

const initialState = {
    currentPageResultIds: [],
    listingCategoryName: null
};

const resultIds = data => {
    const listings = data.data;
    return listings
        .filter(l => !l.attributes.deleted && l.attributes.state === 'published')
        .map(l => l.id);
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case NOTIFICATIONS_SENT:
            return {
                ...state,
                ...payload
            }

        default:
            return state;
    }
}


// ================ Action creators ================ //


export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {

    const urlSearchParams = new URLSearchParams(search)
    const selectedListingId = urlSearchParams.get("selectedListing")

    const { data: listingReponse } = await sdk.ownListings.show({ id: selectedListingId })
    const listing = listingReponse.data

    const { publicData, geolocation, title, description } = listing?.attributes || {}
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const radiusLimitInMeters = config.serviceProviderNotificationRadiusInKM * 1000;
    const bounds = locationBounds(geolocation, radiusLimitInMeters);
    const boundsString = `${bounds.ne.lat},${bounds.ne.lng},${bounds.sw.lat},${bounds.sw.lng}`; 

    let listingSearchParams = {
        origin: `${geolocation.lat},${geolocation.lng}`,
        bounds: boundsString,
    }

    Object.keys(publicData)?.forEach(key => {
        if (key == "location" || key == "transactionProcessAlias" || key == "unitType" || key == "listingType" || key == "date") {
            return
        }
        listingSearchParams = {
            ...listingSearchParams,
            [`pub_${key}`]: publicData[key],
        }
    })

    const listingCategories = config.categoryConfiguration.categories;
    const filterConfigs = {
        listingFieldsConfig,
        defaultFiltersConfig,
        listingCategories,
    };

    const cleanedListingSearchParams = cleanSearchFromConflictingParams(listingSearchParams, filterConfigs, sortConfig);

    const searchParams = {
        page: 1,
        perPage: 100,
        pub_listingType: "service",
        states: "published",
        include: ['author'],
        ...cleanedListingSearchParams
    }
    const adminUrl = createResourceLocatorString('ServicesSearchPage', [{
        "path": "/services",
        "name": "ServicesSearchPage",
        "component": {
            "displayName": "Loadable"
        }
    }], {}, searchParams)

    const validServices = await postGetValidServices({ searchParams, title, description, slug: createSlug(title), selectedListingId, adminUrl })

    dispatch({
        type: NOTIFICATIONS_SENT,
        payload: {
            slug: createSlug(title), selectedListingId
        }
    })
    return validServices


};

